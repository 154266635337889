import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Favicon from '../images/favicon.ico';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

export default function BlogPostTemplate({ data }) {
  const post = data.ghostPost;
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  return (
    <Layout>
      <Helmet
        style={[
          {
            cssText: `
.kg-embed-card iframe {
  width: 100%;
  height: 450px;
}

      `,
          },
        ]}
      >
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>{post.title}</title>
        <meta name="description" content={post.meta_description} />
      </Helmet>

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              ></rect>
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              ></rect>
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width="404"
              height="384"
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="384"
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              ></rect>
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <div className="flex justify-between">
              <div className="mt-4 flex items-center gap-2 md:gap-4">
                <p className="text-secondary font-secondary text-[12px] lg:text-[22px]">
                  Partagez-le
                </p>
                <div className="flex items-center gap-4">
                  <Link
                    target="_blank"
                    to={
                      'https://www.facebook.com/sharer/sharer.php?u=' +
                      currentUrl
                    }
                  >
                    <FaFacebookF color="#2B88D9" size="22px" />
                  </Link>
                  <Link
                    target="_blank"
                    to={
                      'https://www.linkedin.com/sharing/share-offsite?url=' +
                      currentUrl
                    }
                  >
                    <FaLinkedinIn color="#61CAFF" size="22px" />
                  </Link>

                  <Link
                    target="_blank"
                    to={'https://twitter.com/intent/tweet?url=' + currentUrl}
                  >
                    <AiOutlineTwitter color="#6EACE2" size="22px" />
                  </Link>
                </div>
              </div>
              <p className="bg-primary relative z-10 flex items-center rounded-full px-3 py-1.5 text-sm font-medium text-white hover:bg-gray-100"></p>
            </div>
            <h1 className="mt-4">
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {post.title}
              </span>
            </h1>
          </div>
          <div
            className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto"
            dangerouslySetInnerHTML={{ __html: post.html }}
          ></div>
        </div>
      </div>
    </Layout>
  );
}

export const postQuery = graphql`
  query ($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      slug
      html
      meta_title
      meta_description
    }
  }
`;
